import React from "react"

function Quote({ data }) {
    return (

        <section className="quote">
            <div className="container py-5">
                <div className="row frame justify-content-center pt-5 ">
                    <div className="col-lg-3 col-sm-12 text-center ">
                        <img src={data.link.childImageSharp.fluid.src} className="image" alt="quote" />
                    </div>
                    <div className="col-lg-7 col-xs-12">
                        <div className="frame-caption mt-0">
                            {data.text}
                        </div>
                        <div className="frame-author">
                            {data.author}
                        </div>
                    </div>
                </div>
                <div className="row py-5 justify-content-center sponsor">
                    {data.sponsor.map((key, index) => <div key={index} className="col-lg-3 cold-xs-12 text-center"><img src={key.link.childImageSharp.fluid.src} alt={key.alt} /></div>)}
                </div>
            </div>

        </section>

    )
}

export default Quote
