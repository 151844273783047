import React, { useState } from "react"
import Fade from 'react-reveal/Fade'
import ReadMore from "../common/ReadMore"
import ReadLess from "../common/ReadLess"
import Img from "gatsby-image"

function HeroPortfolio({ data, options }) {
    const [open, setOpen] = useState(false);

    return (
        <div className="container my-5">
            <div className="row">
                <div className="col-lg-12">
                    <Fade>
                        <h2 className="text-center">{data.title}</h2>
                        <hr className="divider mb-5" />
                    </Fade>
                </div>
                <div className="col-lg-5 col-xs-12 mb-5 text-center">
                    <Fade left>
                        <div className="bio-music">
                            <Img fluid={data.fluid.childImageSharp.fluid} />
                        </div>
                    </Fade>
                </div>

                <div className="col-lg-7">
                    <Fade right>
                        {data.description.slice(0, 6).map((key, index) => <p key={index} className="mb-4"> {key.p} </p>)}
                    </Fade>
                </div>
                <div className="col-12 mt-3 mb-5">
                    {open ? data.description.slice(6, data.description.length).map((key, index) => <p key={index} className="mb-4"> {key.p} </p>) : ''}
                    <div className="text-center">
                        <button className="more"
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}>
                            {open ? <ReadLess data={options.viewless} /> : <ReadMore data={options.viewmore} />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroPortfolio
