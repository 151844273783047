import React from "react"
import Fade from 'react-reveal/Fade';
import {Parallax} from 'react-parallax';

function Footer(props) {  
    
    return (
        <section className="bg-dark text-white" id="contact">
          <Parallax
            bgImage={props.data.image.childImageSharp.fluid.src}
            strength={500}
            renderLayer={_percentage => (
              <div>
                <div
                  style={{
                    position: "absolute",
                    background: "#000",
                    opacity: "0.7",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%"
                    
                  }}
                  />
                </div>
                )}
          >
              <div>
              
              <div className="container-fluid text-center  my-5">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12 mb-5 px-5">
                        <Fade>
                          <div className="social-contact">
                            {props.data.socialmedia.map((key)=> <a key={key.name} href={key.link} target="_blank" rel="noopener noreferrer"><i className={`fab fa-2x fa-${key.name}`}></i></a>)}
                          </div>
                          <div className="text"><i className="fas fa-location-arrow mt-3"></i> {props.data.address} </div>
                          <div className="text"><i className="far fa-envelope"></i> <a href={`mailto:venezuelanharp@gmail.com`}> venezuelanharp@gmail.com</a></div>
                          <div className="text"><i className="far fa-envelope"></i> <a href={`mailto:${props.data.email}`}> {props.data.email}</a></div>
                        </Fade> 
                        </div>
                      </div> 
              </div>
            
            </div>
          </Parallax>  
        </section>)
}

export default Footer 