import React from "react"
import Fade from 'react-reveal/Fade';

function Media(props) {
    
    return (
        <section id="media">
          <div className="container-fluid m-0">
            <div className="row ">
              <div className="col-lg-12 text-center title py-5">
                <Fade><h2>{props.data.title}</h2>
                    <hr className="divider"/>
                </Fade>
              </div>
              <div className="col-lg-6 media media-light">
              <div className="row w-100">
                <div className="col-12 mb-3">
                    <Fade left>
                     <h3>{props.data.iframe[0].name}</h3>
                    </Fade>
                </div>
                <div className="col-12 ">
                    <Fade left>
                      <iframe title="youtube" src={props.data.iframe[0].link} width="100%" height="380" frameBorder="0"></iframe>
                    </Fade>
                </div>
              </div>
              </div>
              <div className="col-lg-6 media media-dark">
              <div className="row w-100">
                <div className="col-12 mb-3">
                    <Fade right>
                     <h3>{props.data.iframe[1].name}</h3>
                    </Fade>
                </div>
                <div className="col-12 ">
                    <Fade right>
                        <iframe title="spotify" src={props.data.iframe[1].link} width="100%" height="380" frameBorder="0"></iframe>
                    </Fade>
                </div>
              </div>

              </div>
            </div>
          </div>
        </section>
       )
}

export default Media